import React from "react";
import { ContainerColored, WrapperColored } from "../../global";
import { FC } from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import Button from "@studytools/core/ui/components/Button";

const PriceType = styled(Typography)({
  paddingTop: 15,
});

const pricingHeight = "570px";

const Pricing: FC = () => {
  return (
    <WrapperColored id="connect">
      <ContainerColored>
        <Typography variant="h3" align="center">
          PRICING
        </Typography>
        <PricingContainer>
          <PricingBox className="free">
            <Padder></Padder>
            <PricingItem>
              <Header>
                <PriceType variant="h4" align="center" style={{ marginTop: 20 }}>
                  Free
                </PriceType>
                <Typography variant="body1" align="center" style={{ marginTop: 10 }}>
                  Includes NZ Math Skills Numeracy course
                </Typography>
              </Header>
              <Text>
                <List>
                  <li>
                    <Typography variant="body1">
                      Sign up to watch our full <Link href="/explore">course catalogue</Link> for <b>free</b>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Includes{" "}
                      <Link href="/app/course/609dfce7c0d296292221247f/summary">NZ Math Skills Numeracy course</Link>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Make up to 3 of <b>your own</b> Ako Maps, with nodes, connectors, layers, and tags
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Unlimited <b>image uploads</b>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      <b>Share</b> your maps with others
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      <b>Export</b> as PDF
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      <b>View</b> all your existing Ako Maps
                    </Typography>
                  </li>
                </List>
              </Text>
            </PricingItem>
            <span></span>
          </PricingBox>
          <PricingBox className="premium">
            <Padder></Padder>
            <PricingItem>
              <Header>
                <PriceType variant="h4" align="center">
                  Premium
                </PriceType>
                <Typography variant="h6" align="center" style={{ marginTop: 10 }}>
                  First 12 months FREE!
                </Typography>
                <Typography variant="body1" align="center" style={{ marginTop: 10 }}>
                  Thereafter NZ$5 / month
                </Typography>
                <Typography variant="body1" align="center">
                  Students NZ$3 / month <sup>*</sup>
                </Typography>
              </Header>
              <Text>
                <List>
                  <li>
                    <Typography variant="body1">
                      <i>Everything in &apos;Free&apos;, plus</i>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Make <b>unlimited</b> private and public Ako Maps
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Get <b>Edit and Admin</b> access to maps shared with you
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Get access to <b>memory features</b> (such as &apos;test yourself&apos; and automatic flashcards)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Get <b>offline</b> access to Ako Maps
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Participate in <b>live edit</b> sessions
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      <b>Create</b> and <b>join</b> classes
                    </Typography>
                  </li>
                  <Typography variant="subtitle2" style={{ position: "absolute", bottom: 8, left: 44 }}>
                    <sup>*</sup>School email address required
                  </Typography>
                </List>
              </Text>
            </PricingItem>
            <span></span>
          </PricingBox>
          <PricingBox className="organisations">
            <Padder></Padder>
            <PricingItem>
              <Header>
                <PriceType variant="h4" align="center" style={{ marginTop: 40 }}>
                  Organisations
                </PriceType>
              </Header>
              <Text>
                <List>
                  <Typography variant="body1" align="center">
                    Contact us to discuss custom options for schools or businesses
                  </Typography>
                  <Button
                    fill="blue"
                    onClick={() => {
                      location.href = "mailto:contact@cognitiveperformancelabs.com";
                    }}
                  >
                    <Typography variant="h6">CONTACT US</Typography>
                  </Button>
                  <Typography variant="caption">
                    <i>contact@cognitiveperformancelabs.com</i>
                  </Typography>
                </List>
              </Text>
            </PricingItem>
            <span></span>
          </PricingBox>
        </PricingContainer>
        <FootNote>
          <Typography variant="caption" align="center">
            <i>
              New users get a 12 month free trial of &apos;Premium&apos; access when they sign up to Ako Maps. No credit card
              required.
              <br></br>
              Prices and features offered are subject to change.
            </i>
          </Typography>
        </FootNote>
      </ContainerColored>
    </WrapperColored>
  );
};

export default Pricing;

const PricingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 32px auto 0;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: auto;
  }
`;
const PricingBox = styled.div`
  overflow: hidden;
  position: relative;
  margin-bottom: 32px;
  height: calc(${pricingHeight} + 80px);
  width: 370px;

  > span {
    position: absolute;
    bottom: 0;
    right: 0;
    height: calc(100% - 64px);
    width: calc(100% - 64px);
    border-radius: 10px;
    z-index: 0;
    @media (max-width: ${props => props.theme.screen.sm}) {
      height: calc(100% - 32px);
      width: calc(100% - 32px);
    }
    @media (max-width: 360px) {
      width: 100%;
    }
  }
  @media (max-width: ${props => props.theme.screen.lg}) {
    margin-left: 32px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 328px;
  }
  @media (max-width: 360px) {
    margin-left: 0;
  }
  &.free {
    > :nth-child(2) {
      background-color: #7eddcd;
    }
    > span {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #7eddcd);
    }
  }
  &.premium {
    > :nth-child(2) {
      background-color: #6b99e2;
    }
    > span {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #6b99e2);
    }
  }
  &.organisations {
    > :nth-child(2) {
      background-color: #8b7fdf;
    }
    > span {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #8b7fdf);
    }
  }
`;
const PricingItem = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 10px 32px;
  color: black;
  position: relative;
  flex-direction: column;
  text-align: center;
  //max-width: 296px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin: 0 32px;
  z-index: 1;
  height: ${pricingHeight};
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 0;
  }
  @media (max-width: 360px) {
    max-width: unset;
    width: 100%;
  }
`;
const Header = styled.div`
  color: white;
  height: 260px;
`;

const Text = styled.div`
  margin: -32px;
  padding: 16px 32px 0;
  height: 100%;
  background-color: white;
`;

const List = styled.ul`
  padding-inline-start: 0px;
  > li > p {
    display: list-item;
    padding-bottom: 8px;
  }
  > span {
    position: absolute;
    bottom: 32px;
    left: 0;
    margin: 16px;
    width: 264px;
  }
  > span:last-child {
    bottom: 0;
    left: 0;
  }
`;

const Padder = styled.div`
  position: relative;
  top: 0;
  left: 0;
  margin: 0 32px -8px;
  padding: 8px 8px 16px;
  width: 296px;
  height: 56px;
  border-radius: 10px 10px 0 0;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 0 auto -8px 0;
  }
  @media (max-width: 360px) {
    max-width: unset;
    width: 100%;
  }
`;

const FootNote = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
`;

const Link = styled.a`
  text-decoration: underline;
`;
