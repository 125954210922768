import React, { FC } from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import Pricing from "../components/common/pricing/Pricing";
import Footer from "../components/sections/footer";

import theme from "@studytools/core/ui/theme/muiTheme";
import { ThemeProvider } from "@material-ui/core";

const PricingPage: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Pricing" />
        <Navigation />
        <Pricing />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default PricingPage;
